import { defineStore } from 'pinia';
import type { LayoutStoreState } from '~/types/stores/layout';

export const useLayoutStore = defineStore({
  id: 'layout',
  state: (): LayoutStoreState => ({
    showMobileMenu: false,
    showPageLoader: false,
  }),
  getters: {
    getShowMobileMenu: (state) => state.showMobileMenu,
    getShowPageLoader: (state) => state.showPageLoader,
  },
  actions: {
    toggleMobileMenu() {
      this.showMobileMenu = !this.showMobileMenu;
    },
    setPageLoader(pageLoaderState: boolean) {
      this.showPageLoader = pageLoaderState;
      this.showMobileMenu = false;
    },
  },
});
